<template>
  <generic-overview
    :route="route"
    :dialog="dialog"
    :actions="actions"
    :focused="focused"
    icon="apps"
    title="Categories"
  >
    <v-btn slot="toolbar-actions" icon @click.native="toggleExpandAll">
      <v-icon>{{ expandToBoolean ? "unfold_less" : "unfold_more" }}</v-icon>
    </v-btn>
    <template slot="content">
      <entity-data-provider entity-name="category" :includes="includes">
        <div slot-scope="props">
          <v-tree
            ref="categoriesTree"
            :items="props.data"
            :loading="props.isLoading"
            :selected="id"
            item-text="name.nl_be"
            item-value="id"
            @select="toDetail"
          >
            <div>
              NO NODE SLOT PROVIDED
            </div>
          </v-tree>
          <v-btn
            v-for="orphan in orphans"
            :key="orphan.id"
            color="warning"
            @click="toDetail(orphan)"
          >
            <v-icon>warning</v-icon>
            {{ orphan.name.nl_be || orphan.id }}
          </v-btn>
        </div>
      </entity-data-provider>
    </template>
    <router-view slot="dialog" />
  </generic-overview>
</template>
<script type="text/babel">
import EntityDataProvider from "@/components/legacy/generic/data/EntityDataProvider";
import VTree from "@/components/legacy/generic/v-tree/VTree.vue";
import GenericOverview from "@/components/legacy/generic/overview/Overview";
import EntityOverviewMixin from "@/components/legacy/generic/entity/EntityOverviewMixin.vue";

export default {
  name: "companies-overview",
  components: {
    EntityDataProvider,
    VTree,
    GenericOverview
  },
  mixins: [EntityOverviewMixin],
  props: {
    id: Number,
    default: undefined
  },
  data() {
    return {
      route: "distribution.categories",
      routeCreate: "distribution.categories.create",
      routeDetails: "distribution.categories.show",
      url: "api/v1/categories",
      tree: [],
      includes: ["parents", "children", "categorySubjects.subject"],
      actions: [
        {
          icon: "add",
          color: "pink",
          to: { name: "distribution.categories.create" }
        }
      ],
      expandToBoolean: false
    };
  },
  computed: {
    orphans() {
      return this.$store.getters["entities/getCategoryOrphans"];
    }
  },
  methods: {
    toDetail({ id }) {
      this.$router.push({
        name: "distribution.categories.show",
        params: { id: id }
      });
    },
    toggleExpandAll() {
      this.$refs.categoriesTree.expandAll(this.expandToBoolean);
      this.expandToBoolean = !this.expandToBoolean;
    }
  }
};
</script>
