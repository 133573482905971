import { render, staticRenderFns } from "./CategoryDetailsAncestry.vue?vue&type=template&id=78e5dafa&"
import script from "./CategoryDetailsAncestry.vue?vue&type=script&lang=js&"
export * from "./CategoryDetailsAncestry.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileAvatar } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBtn,VFabTransition,VFlex,VIcon,VLayout,VList,VListTile,VListTileAction,VListTileAvatar,VListTileContent,VListTileTitle,VSelect,VSlideYTransition,VSubheader})
