<template>
  <div>
    <v-toolbar
      color="primary"
      dark
      :tabs="!!tabs"
      :loading="true"
      class="elevation-0"
    >
      <v-btn icon @click.native="$emit('close')">
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title>
        <slot name="title" />
      </v-toolbar-title>
      <v-spacer />
      <v-btn v-if="!loading && changed" icon @click="$emit('reset')">
        <v-icon>undo</v-icon>
      </v-btn>
      <v-btn v-if="!loading && changed" icon @click="$emit('save')">
        <v-icon>save</v-icon>
      </v-btn>
      <v-tabs
        v-if="!!tabs"
        slot="extension"
        color="primary"
        dark
        slider-color="accent"
        align-with-title
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.key"
          :to="tab.to"
          :href="tab.href"
          :disabled="tab.disabled"
          ripple
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>
    </v-toolbar>
    <v-progress-linear
      indeterminate
      class="my-0"
      :color="updating ? 'accent' : loading ? 'primary' : 'white'"
      height="2"
    />
  </div>
</template>

<script>
export default {
  name: "entity-details-header",
  props: {
    loading: Boolean,
    changed: Boolean,
    updating: Boolean,
    tabs: Array
  }
};
</script>
