<template>
  <v-card color="white" class="primary-details" height="100%">
    <entity-details-header
      :loading="isLoading"
      :changed="isChanged"
      :updating="isUpdating"
      @close="$router.push({ name: 'distribution.categories' })"
      @reset="buildEditableEntity"
      @save="onValidate"
    >
      <template slot="title">
        <span>category</span>
        <span v-html="editableEntity.name.nl_be" />
      </template>
    </entity-details-header>
    <v-slide-y-transition>
      <v-card-text v-if="isLoaded || isCreate" class="primary-details-content">
        <form>
          <v-text-field
            v-model="editableEntity.name.en_gb"
            v-validate="'max:255'"
            label="Name - English"
            :counter="255"
            :error-messages="errors.collect('name.en_gb')"
            data-vv-name="name.en_gb"
          />
          <v-text-field
            v-model="editableEntity.name.nl_be"
            v-validate="'required|max:255'"
            label="Name - Dutch (Belgian)"
            :counter="255"
            :error-messages="errors.collect('name.nl_be')"
            data-vv-name="name.nl_be"
            required
          />
          <v-text-field
            v-model="editableEntity.name.nl_nl"
            v-validate="'max:255'"
            label="Name - Dutch"
            :counter="255"
            :error-messages="errors.collect('name.nl_nl')"
            data-vv-name="name.nl_nl"
          />
          <v-text-field
            v-model="editableEntity.name.fr_fr"
            v-validate="'max:255'"
            label="Name - French"
            :counter="255"
            :error-messages="errors.collect('name.fr_fr')"
            data-vv-name="name.fr_fr"
          />
          <v-text-field
            v-model="editableEntity.name.fr_be"
            v-validate="'max:255'"
            label="Name - French (Belgian)"
            :counter="255"
            :error-messages="errors.collect('name.fr_be')"
            data-vv-name="name.fr_be"
          />
          <v-text-field
            v-model="editableEntity.name.de_de"
            v-validate="'max:255'"
            label="Name - German"
            :counter="255"
            :error-messages="errors.collect('name.de_de')"
            data-vv-name="name.de_de"
          />
          <v-switch
            v-if="!hasParents"
            v-model="editableEntity.isRoot"
            label="Is root"
            color="primary"
          />
          <v-text-field
            v-model="editableEntity.price"
            type="number"
            step="any"
            label="Price"
          />
          <subject-mappings :category="editableEntity" />
          <ancestry :category="editableEntity" />
        </form>
        <v-dialog
          v-if="!isCreate"
          v-model="isDeleteDialogOpen"
          persistent
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">
              Delete employee?
            </v-card-title>
            <v-card-text>
              Are you really sure you want to delete category
              <span class="primary--text" style="font-weight: bold;">
                {{ storedEntity.name.nl_be }} </span
              >? If you make a mistake, we will pour mustard in your eye.
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                v-if="!isUpdating"
                flat
                @click.native="isDeleteDialogOpen = false"
              >
                Nevermind
              </v-btn>
              <v-btn
                v-if="!isUpdating"
                color="primary"
                flat
                @click.native="deleteEntity"
              >
                I'm sure
              </v-btn>
              <v-progress-circular v-if="isUpdating" indeterminate />
              <v-spacer v-if="isUpdating" />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-slide-y-transition>
  </v-card>
</template>

<script type="text/babel">
import { isNil, cloneDeep, sortBy, isArray, isEmpty } from "lodash";
import CrudMixin from "../../../components/legacy/generic/crud/CrudMixin.vue";
import EntityDetailsHeader from "../../../components/legacy/generic/entity/EntityDetailsHeader";
import Ancestry from "./CategoryDetailsAncestry";
import SubjectMappings from "./CategoryDetailSubjectMappings";

export default {
  name: "bo-dis-category-details",
  components: { EntityDetailsHeader, SubjectMappings, Ancestry },
  mixins: [CrudMixin],
  data: () => {
    return {
      entityName: "category",
      editableEntity: {
        id: null,
        name: {
          en_gb: null,
          nl_be: null,
          nl_nl: null,
          fr_be: null,
          fr_fr: null,
          de_de: null
        },
        price: null,
        isRoot: null,
        isValid: null,
        sortPosition: null,
        children: [],
        parents: [],
        categorySubjects: []
      },
      deleteRedirectRouteName: "distribution.categories",
      createRedirectRouteName: "distribution.categories.show",
      includes: [
        "children",
        "parents",
        "categorySubjects.filterQuestionOptions",
        "categorySubjects.subject.questions.options.parentOption.parentOption.parentOption",
        "categorySubjects.subject.questions.parentQuestion.parentQuestion.parentQuestion"
      ]
    };
  },
  computed: {
    hasParents() {
      return (
        isArray(this.editableEntity.parents) &&
        !isEmpty(this.editableEntity.parents)
      );
    }
  },
  methods: {
    transformEntity() {
      const entity = cloneDeep(
        this.$store.getters["entities/getEntity"]({
          name: this.entityName,
          id: this.id
        })
      );
      if (!isNil(entity)) {
        entity.categorySubjects = cloneDeep(
          this.$store.getters["entities/getEntities"]({
            name: "categorySubject",
            ids: entity.categorySubjects
          })
        );
      }
      return entity;
    },
    createComparisonObject(obj) {
      const comparisonObject = {};
      const sortedObjectKeys = sortBy(Object.keys(obj));
      for (let i = 0; i < sortedObjectKeys.length; i++) {
        const property = sortedObjectKeys[i];
        if (property === "categorySubjects") {
          comparisonObject[property] = this.createComparisonObject(
            obj[property]
          );
        } else {
          comparisonObject[property] = obj[property];
        }
      }
      return comparisonObject;
    },
    goToOverview() {
      this.$router.push({ name: "distribution.categories" });
    }
  }
};
</script>
<style>
.primary-details {
  overflow-y: hidden;
}

.primary-details-content {
  height: calc(100% - 68px);
  overflow-y: auto;
}

.primary-details-tabbed-content {
  height: calc(100% - 144px);
  overflow-y: auto;
}
</style>
