<template>
  <v-layout>
    <v-flex xs12 md6>
      <v-list>
        <v-subheader>Parents</v-subheader>
        <template v-for="(item, index) in parents">
          <v-list-tile avatar :key="index">
            <v-list-tile-avatar color="grey">
              <img :src="item.avatar" />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title v-html="item.name.nl_be" />
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-list>
    </v-flex>
    <v-flex xs12 md6>
      <v-list>
        <v-fab-transition>
          <v-btn
            v-if="!isAddCategory"
            fab
            small
            right
            absolute
            color="primary"
            @click.stop="isAddCategory = true"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-subheader>
          Children
        </v-subheader>
        <draggable v-model="children">
          <template v-for="(item, index) in children">
            <v-list-tile avatar :key="index">
              <v-list-tile-avatar color="grey">
                <img :src="item.avatar" />
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ item.name.nl_be }}
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn icon @click="removeChild(index)">
                  <v-icon>clear</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </template>
        </draggable>
        <v-slide-y-transition>
          <v-list-tile v-if="isAddCategory" avatar>
            <v-list-tile-action />
            <v-list-tile-content>
              <data-provider
                v-if="isAddCategory"
                entity-name="category"
                style="width: 100%;"
              >
                <v-select
                  v-if="isAddCategory"
                  v-model="addCategoryId"
                  slot-scope="props"
                  browser-autocomplete
                  :items="props.data"
                  :loading="props.isLoading"
                  item-text="name.nl_be"
                  item-value="id"
                  autocomplete
                  @input="addChild"
                />
              </data-provider>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn icon @click="clearAddCategory">
                <v-icon>undo</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
        </v-slide-y-transition>
      </v-list>
    </v-flex>
  </v-layout>
</template>
<script type="text/babel">
import { map } from "lodash";
import draggable from "vuedraggable";
import DataProvider from "@/components/legacy/generic/data/EntityDataProvider";

export default {
  name: "bo-dis-category-ancestry",
  components: {
    DataProvider,
    draggable
  },
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      storeName: "category",
      isAddCategory: false,
      addCategoryId: null
    };
  },
  computed: {
    parents() {
      return this.$store.getters["entities/getEntities"]({
        name: "category",
        ids: this.category.parents
      });
    },
    children: {
      get: function() {
        return this.$store.getters["entities/getEntities"]({
          name: "category",
          ids: this.category.children
        });
      },
      set: function(value) {
        this.category.children = map(value, "id");
      }
    }
  },
  methods: {
    removeChild: function(index) {
      this.category.children.splice(index, 1);
    },
    addChild: function(id) {
      this.category.children.push(id);
      this.clearAddCategory();
    },
    clearAddCategory() {
      this.isAddCategory = false;
      this.addCategoryId = null;
    }
  }
};
</script>
