<template>
  <li
    :class="{
      'first-node': first,
      'only-node': only,
      leaf: isLeaf,
      selected: isSelected
    }"
  >
    <div draggable="true" class="tree-node-el">
      <v-btn v-if="!isLeaf" icon class="ma-0" @click="toggle()">
        <v-icon>{{
          isOpen
            ? "expand_more"
            : node.children.length > 0
            ? "chevron_right"
            : "clear"
        }}</v-icon>
      </v-btn>
      <span>
        <span class="node-title" @click="select({ id: node.id })">
          {{ label }}
        </span>
        <span
          v-for="subject in node.subjects"
          class="body-2 pa-2 grey lighten-2"
          v-html="subject"
          :key="subject.id"
        />
      </span>
    </div>
    <ul v-show="isOpen" class="halo-tree">
      <v-tree-node
        v-for="(childNode, index) in node.children"
        :key="childNode.id"
        :ref="`v-tree-node-${index}`"
        :node="childNode"
        :selected="selected"
        :only="node.children.length === 1"
        @select="select"
        @open="open"
      />
    </ul>
  </li>
</template>
<script type="text/babel">
import VTreeNode from "../v-tree/VTreeNode.vue";
import { isArray, isEmpty, isNil, each } from "lodash";

export default {
  name: "v-tree-node",
  components: { VTreeNode },
  props: {
    node: { type: Object, required: true },
    selected: {
      type: Number || String,
      required: false,
      validator: value => !isNaN(value)
    },
    only: Boolean,
    first: Boolean
  },
  data: () => {
    return {
      isOpen: false,
      isLastChild: false
    };
  },
  computed: {
    label: function() {
      return isNil(this.node.label) ? "UNNAMED" : this.node.label;
    },
    isSelected: function() {
      return this.node.id === parseInt(this.selected);
    },
    isLeaf: function() {
      return (
        isNil(this.node.children) ||
        isArray(this.node.children && isEmpty(this.node.children))
      );
    }
  },
  watch: {
    selected: function(newValue) {
      if (this.node.id === parseInt(newValue)) {
        this.open(true);
      }
    }
  },
  created: function() {
    if (!isNil(this.selected) && this.node.id === parseInt(this.selected)) {
      this.open(true);
    }
  },
  methods: {
    toggle: function() {
      if (this.node.children.length > 0) {
        this.isOpen = !this.isOpen;
      }
    },
    select: function({ id }) {
      this.$emit("select", { id: id });
    },
    open: function(cascade) {
      this.isOpen = true;
      if (cascade) {
        this.$emit("open", true);
      }
    },
    expandAll(isClose) {
      this.isOpen = !isClose;
      each(this.node.children, (node, index) => {
        const ref = this.$refs[`v-tree-node-${index}`][0];
        ref.expandAll(isClose);
      });
    }
  }
};
</script>
