<template>
  <v-card>
    <v-card-text>
      Val: {{ optionIds }}
      <template v-for="(question, index) in filterQuestions">
        <v-layout row wrap class="subheading" :key="index">
          <v-flex>
            {{ question.label.nl_be }}
            <v-btn
              v-if="hasSelection(question)"
              icon
              small
              class="ma-0"
              @click="clearSelection(question)"
            >
              <v-icon>clear</v-icon>
            </v-btn>
          </v-flex>
          <v-spacer v-if="index === 0" />
          <v-btn
            v-if="index === 0"
            small
            class="ma-0"
            @click="removeCategorySubject(categorySubject)"
          >
            <v-icon>clear</v-icon>
          </v-btn>
        </v-layout>
        <v-radio-group
          :key="index"
          v-if="!isQuestionMultiple(question)"
          v-model="optionIds[question.id]"
          column
          class="pt-0"
        >
          <v-radio
            v-for="option in getOptions(question.options)"
            :key="option.id"
            :label="option.label.nl_be"
            color="primary"
            :value="option.id"
          />
        </v-radio-group>
        <template v-else>
          <template v-for="option in getOptions(question.options)">
            <v-checkbox
              v-if="isQuestionMultiple(question)"
              :key="option.id"
              v-model="optionIds[question.id]"
              :label="option.label.nl_be"
              color="primary"
              :value="option.id"
              hide-details
            />
          </template>
        </template>
      </template>
    </v-card-text>
  </v-card>
</template>
<script type="text/babel">
import Vue from "vue";
import {
  concat,
  each,
  filter,
  forOwn,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  isNull,
  map,
  toNumber,
  uniq
} from "lodash";

export default {
  name: "bo-dis-category-subject",
  components: {},

  props: {
    categorySubject: { type: Object, required: true }
  },
  data: () => {
    return {
      isOpen: false,
      optionIds: {},
      testValue: []
    };
  },
  computed: {
    subject: function() {
      return this.$store.getters["entities/getEntity"]({
        name: "subject",
        id: this.categorySubject.subject
      });
    },
    filterQuestions: function() {
      const alwaysVisibleQuestions = this.$store.getters[
        "entities/getQuestionsByIds"
      ]({ ids: [], isExcludedIncluded: true, includeAlwaysVisible: true });
      const questions = alwaysVisibleQuestions.concat(
        this.$store.getters["entities/getQuestionsByIds"]({
          ids: this.subject.questions
        })
      );
      return filter(questions, o => {
        return o.isFilterable;
      });
    },
    hasFilterActive: function() {
      let hasFilterActive = false;
      if (!isNil(this.optionIds)) {
        forOwn(this.optionIds, o => {
          if (isArray(o) && o.length > 0) {
            hasFilterActive = true;
          }
        });
      }
      return hasFilterActive;
    }
  },
  watch: {
    "categorySubject.options": function(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.mapOptionIdsPerQuestionId(newValue);
      }
    },
    optionIds: {
      handler: function() {
        let optionIds = [];
        forOwn(this.optionIds, function(questionOptionIds) {
          if (
            !isNull(questionOptionIds) &&
            !(isArray(questionOptionIds) && isEmpty(questionOptionIds))
          ) {
            if (!isArray(questionOptionIds)) {
              questionOptionIds = [questionOptionIds];
            }

            // Run them all through toNumber to make sure there are no strings in disguise, then concat the array to the combined array.
            optionIds = concat(
              optionIds,
              map(questionOptionIds, optionId => toNumber(optionId))
            );
          }
        });
        const uniqOptionIds = uniq(optionIds);
        this.categorySubject.options = uniqOptionIds;
      },
      deep: true
    }
  },
  created: function() {
    // Why not have option ids as a computed property you ask?
    // I tried it and the mapped optionId arrays that are used as v-model properties for the wrapped-checkboxes
    // lose their reactivity resulting in badly functioning checkboxes. (Uncheck is not noticed)
    // - Jelle
    this.mapOptionIdsPerQuestionId(this.categorySubject.options);
  },
  methods: {
    getOptions(optionIds) {
      return this.$store.getters["entities/getQuestionOptionsByIds"]({
        ids: optionIds
      });
    },
    mapOptionIdsPerQuestionId: function(options) {
      let mappedOptionIds = {};
      each(this.filterQuestions, question => {
        let filteredOptionIds = filter(options, optionId => {
          return map(this.getOptions(question.options), o => o.id).includes(
            optionId
          );
        });
        if (this.isQuestionMultiple(question)) {
          Vue.set(mappedOptionIds, question.id, filteredOptionIds);
        } else {
          Vue.set(
            mappedOptionIds,
            question.id,
            filteredOptionIds.length === 0 ? null : filteredOptionIds[0]
          );
        }
      });
      Vue.set(this, "optionIds", mappedOptionIds);
    },
    toggle: function() {
      this.isOpen = !this.isOpen;
    },
    remove: function() {
      this.$store.dispatch("category/removeSubjectMapping", {
        subjectId: this.subject.id
      });
    },
    hasSelection: function(question) {
      const selectedOptionIds = this.optionIds[question.id];
      return (
        !isNull(selectedOptionIds) &&
        !(isArray(selectedOptionIds) && isEmpty(selectedOptionIds))
      );
    },
    clearSelection: function(question) {
      this.optionIds[question.id] = this.isQuestionMultiple(question)
        ? []
        : null;
    },
    removeCategorySubject: function(categorySubject) {
      this.$emit("remove", { categorySubject: categorySubject });
    },
    isQuestionMultiple(question) {
      if (question.isMultiple) return true;
      if (question.type === "INPUT_CHECKBOX") return true;
      return false;
    }
  }
};
</script>
