<template>
  <div class="tree py-2">
    <v-layout v-if="loading" justify-center ma-5>
      <v-progress-circular
        color="primary"
        :size="32 * 2"
        :width="4 * 1.5"
        :indeterminate="true"
        align-center
      />
    </v-layout>
    <ul class="halo-tree" v-if="!loading">
      <v-tree-node
        v-for="(node, index) in tree"
        :key="node.id"
        :ref="`v-tree-node-${index}`"
        :node="node"
        :selected="selected"
        :first="index === 0"
        :only="tree.length === 1"
        @select="select"
      />
    </ul>
  </div>
</template>
<script type="text/babel">
import VTreeNode from "./VTreeNode";
import { filter, map, find, union, each } from "lodash";

export default {
  name: "v-tree",
  components: { VTreeNode },
  props: {
    items: Array,
    loading: Boolean,
    selected: {
      type: Number || String,
      required: false,
      validator: value => !isNaN(value)
    }
  },
  computed: {
    tree() {
      if (!this.loading) {
        const rootNodes = filter(this.items, o => {
          return o.isRoot;
        });
        return this.createChildNodes(rootNodes);
      }
      return [];
    },
    orphans: (state, getters) => {
      if (getters.isLoaded) {
        let allChildIds = [];
        map(state.data, node => {
          allChildIds = union(allChildIds, node.children);
        });
        return filter(
          state.data,
          node => !node.isRoot && !allChildIds.includes(node.id)
        );
      }
      return [];
    }
  },
  methods: {
    select({ id }) {
      this.$emit("select", { id: id });
    },
    createChildNodes(nodes) {
      return map(nodes, node => {
        return {
          id: node.id,
          label: node.name.nl_be,
          children: this.createChildNodes(
            map(node.children, childId =>
              find(this.items, node => node.id === childId)
            )
          ),
          subjects: map(node.categorySubjects, categorySubjectId => {
            const categorySubject = this.$store.getters["entities/getEntity"]({
              name: "categorySubject",
              id: categorySubjectId
            });
            const subject = this.$store.getters["entities/getEntity"]({
              name: "subject",
              id: categorySubject.subject
            });
            return subject.name;
          })
        };
      });
    },
    expandAll(isClose) {
      each(this.tree, (node, index) => {
        const ref = this.$refs[`v-tree-node-${index}`][0];
        ref.expandAll(isClose);
      });
    }
  }
};
</script>
<style>
.halo-tree {
  font-size: 16px;
}

.halo-tree li,
.halo-tree ul {
  list-style-type: none;
  text-align: left;
}

.halo-tree li {
  margin: 0;
  /*padding: 5px 5px 5px 15px;*/
  position: relative;
  list-style: none;
}

.halo-tree .tree-node-el {
  /*background-color: #fff;*/
  /*padding-left: 2px;*/
  position: relative;
  /*z-index: 3;*/
}

.halo-tree > li.first-node.only-node:before {
  border-left: none;
}

.halo-tree li:last-child:before {
  height: 26px;
}
.halo-tree li:before {
  /*border-left: 3px solid #0653A6;*/
  bottom: 50px;
  height: 100%;
  top: -8px;
  width: 1px;
}
.halo-tree li:after,
.halo-tree li:before {
  content: "";
  left: -8px;
  position: absolute;
  right: auto;
  border-width: 1px;
}

.halo-tree .tree-open {
  line-height: 13px;
}

.halo-tree .tree-close,
.halo-tree .tree-open {
  display: inline-block;
  /*width: 14px;*/
  /*height: 14px;*/
  text-align: center;
  line-height: 13px;
  /*border: 1px solid #888;*/
  /*border-radius: 2px;*/
  /*background: #fff;*/
}

/*.halo-tree .tree-open:after {*/
/*content: "\2013";*/
/*font-style: normal;*/
/*}*/

.halo-tree .inputCheck {
  display: inline-block;
  position: relative;
  width: 14px;
  height: 14px;
  /*border: 1px solid #888;*/
  /*border-radius: 2px;*/
  top: 4px;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
}

.halo-tree .check {
  display: block;
  position: absolute;
  font-size: 14px;
  width: 16px;
  height: 16px;
  left: -5px;
  top: -4px;
  border: 1px solid #000;
  opacity: 0;
  cursor: pointer;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  z-index: 2;
}

.halo-tree .node-title {
  padding: 3px;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 2px;
}

.leaf .node-title {
  margin-left: 2rem;
}

.halo-tree ul {
  padding-left: 19px;
}

.halo-tree li:after {
  /*border-top: 3px solid #0653A6;*/
  height: 20px;
  top: 17px;
  width: 28px;
}

.halo-tree li:before {
  /*border-left: 3px solid #0653A6;*/
  bottom: 50px;
  height: 100%;
  top: -8px;
  width: 1px;
}

.halo-tree li.leaf {
  padding-left: 15px;
}

.halo-tree > li.only-node:after {
  border-top: none;
}

.halo-tree li.leaf:after {
  content: "";
  left: -7px;
  position: absolute;
  right: auto;
  /*border-width: 1px;*/
  /*border-top: 3px solid #0653A6;*/
  height: 20px;
  top: 17px;
  width: 25px;
}

.btn-tree-item-toggle {
  padding: 0.2rem 0.25rem 0.05rem;
}

.halo-tree li.selected > .tree-node-el .node-title {
  color: #e3bd00;
  font-weight: bold;
}
</style>
