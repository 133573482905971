<template>
  <div>
    <v-flex xs-12 class="title">
      Subject Mappings
      <v-fab-transition>
        <v-btn
          v-if="!isAddSubject"
          fab
          small
          absolute
          color="primary"
          right
          @click.native.stop="isAddSubject = !isAddSubject"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-flex>

    <div v-if="isAddSubject">
      <v-flex xs12 class="pb-0">
        <data-provider entity-name="subject" style="width: 100%;">
          <v-select
            v-model="subjectId"
            slot-scope="props"
            browser-autocomplete
            :items="filterJobsFromSubjects(props.data)"
            :loading="props.isLoading || isAddSubjectLoading"
            :disabled="isAddSubjectLoading"
            item-text="name"
            item-value="id"
            autocomplete
          />
        </data-provider>
      </v-flex>
      <v-flex xs12 class="text-xs-right pt-0">
        <v-spacer />
        <v-btn flat :disabled="isAddSubjectLoading" @click="clearAddSubject">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="subjectId === null || isAddSubjectLoading"
          @click="addSubjectMapping"
        >
          Add
        </v-btn>
      </v-flex>
    </div>
    <v-alert
      v-else-if="category.categorySubjects.length === 0 && !isAddSubject"
      key="category-subject-alert"
      :value="true"
      outline
      color="info"
      icon="info"
    >
      This category has no mapped subjects.
    </v-alert>

    <v-expansion-panel>
      <v-expansion-panel-content
        v-for="categorySubject in category.categorySubjects"
        :key="categorySubject.subject"
      >
        <div slot="header">
          {{ getSubjectName(categorySubject.subject) }}
        </div>
        <category-subject
          :category-subject="categorySubject"
          @remove="onRemoveCategorySubject"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>
<script type="text/babel">
import CategorySubject from "./CategorySubject";
import DataProvider from "@/components/legacy/generic/data/EntityDataProvider";
import { filter, findIndex, isNil } from "lodash";

export default {
  name: "bo-dis-category-detail-subject-mappings",
  components: { DataProvider, CategorySubject },
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      subjectId: null,
      isAddSubject: false,
      isAddSubjectLoading: false,
      isShowingContextMenu: false,
      contextMenuX: 0,
      contextMenuY: 0,
      contextMenuCategorySubject: null
    };
  },
  computed: {},
  methods: {
    getSubjectName(subjectId) {
      const subject = this.$store.getters["entities/getEntity"]({
        name: "subject",
        id: subjectId
      });
      if (!isNil(subject)) {
        return subject.name;
      }
      return "";
    },
    async addSubjectMapping() {
      this.isAddSubjectLoading = true;
      await this.$store.dispatch("entities/fetchSubject", {
        id: this.subjectId,
        includes: [
          "questions.options.parentOption.parentOption.parentOption",
          "questions.parentQuestion.parentQuestion.parentQuestion"
        ]
      });
      this.isAddSubjectLoading = false;
      this.category.categorySubjects.push({
        id: null,
        options: [],
        subject: this.subjectId
      });
    },
    clearAddSubject() {
      this.isAddSubject = false;
      this.subjectId = null;
    },
    filterJobsFromSubjects(subjects) {
      return filter(subjects, o => o.type === "JOB");
    },
    onRemoveCategorySubject({ categorySubject }) {
      const index = findIndex(
        this.category.categorySubjects,
        o => o === categorySubject
      );
      if (index > -1) {
        this.category.categorySubjects.splice(index, 1);
      }
    }
  }
};
</script>
